import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { Autoplay, EffectFade, Lazy, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/lazy";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { radioStream } from "lib/constants";
import { usePlayerStore } from "hooks/usePlayerStore";
import useGlobalSettings from "hooks/useGlobalSettings";
import Logo from "assets/images/Logo";
import { SvgIconTunein, SvgPause, SvgPlay } from "assets/images";
import { MenuItem, Page } from "__generated__/types/graphql.types";

export default function Header({ page }: { page: Page }) {
  const router = useRouter();
  const playerStore = usePlayerStore();
  const globalSettings = useGlobalSettings();

  // @ts-ignore
  const [swiper, setSwiper] = useState<Swiper>();

  return (
    <Disclosure as="header" className="relative flex flex-col items-center px-2">
      {({ open }) => (
        <>
          <div
            className={classNames(
              "absolute top-0 right-0 left-0 -mt-16 h-[calc(100%+4rem)] skew-y-2 transform-gpu transition-all duration-150 lg:h-[calc(75%+4rem)] lg:h-[calc(100%+4rem)]",
              {
                "bg-primary": !open,
                "bg-secondary": open,
              },
            )}
          />
          <div className="hidden lg:absolute lg:mt-8 lg:grid lg:h-16 lg:w-full lg:grid-cols-5 lg:items-center">
            <div className="col-span-2 flex w-full items-center">
              <div className="flex h-12 w-full justify-end rounded-r-full bg-white" />
            </div>
          </div>
          <div className="relative mt-4 flex w-full max-w-7xl flex-col justify-center lg:mt-8 lg:grid lg:h-16 lg:grid-cols-5 lg:justify-between lg:gap-8">
            <div className="hidden lg:col-span-2 lg:flex lg:w-full lg:items-center">
              <div className="flex h-12 justify-end space-x-1 rounded-r-full bg-white pr-6">
                <nav className="flex space-x-1 rounded-r-full pr-6">
                  {globalSettings?.header_navigation?.[0]?.items?.map(item => {
                    item = item as MenuItem;

                    let isCurrentPage = false;
                    let slug: string | undefined = undefined;

                    if (item.page?.[0]) {
                      slug = item.page[0]._slug;

                      if (router.asPath === "/") {
                        isCurrentPage = slug === "index";
                      } else {
                        isCurrentPage = router.asPath.includes(`/${slug}`);
                      }
                    }

                    return (
                      <>
                        {item.link && (
                          <a
                            key={item._id}
                            href={item.link.url}
                            className={classNames(
                              "text-md inline-flex justify-center px-4 py-3 font-medium transition-colors duration-150 hover:bg-gray-100",
                              {
                                "bg-gray-100": isCurrentPage,
                              },
                            )}
                          >
                            {item.label}
                          </a>
                        )}
                        {item.page?.[0] && (
                          <Link
                            key={item._id}
                            href={`/${slug !== "index" ? slug : ""}`}
                            className={classNames(
                              "text-md inline-flex justify-center px-4 py-3 font-medium transition-colors duration-150 hover:bg-gray-100",
                              {
                                "bg-gray-100": isCurrentPage,
                              },
                            )}
                          >
                            {item.label}
                          </Link>
                        )}
                      </>
                    );
                  })}
                </nav>
              </div>
            </div>

            <div className="relative z-10 flex w-full flex-col justify-start lg:hidden">
              {/* Mobile menu button */}
              <div>
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <Disclosure.Panel as="nav" className="mt-8 w-full pb-8 lg:hidden">
                <div className="flex w-full flex-col items-center justify-center space-y-1">
                  {globalSettings?.header_navigation?.[0]?.items?.map(item => {
                    item = item as MenuItem;

                    let isCurrentPage = false;
                    let slug: string | undefined = undefined;

                    if (item.page?.[0]) {
                      slug = item.page[0]._slug;

                      if (router.asPath === "/") {
                        isCurrentPage = slug === "index";
                      } else {
                        isCurrentPage = router.asPath.includes(`/${slug}`);
                      }
                    }

                    return (
                      <>
                        {item.link && (
                          <Disclosure.Button
                            as="a"
                            key={item._id}
                            href={item.link.url}
                            className={classNames("block w-full rounded-md py-2 px-3 text-base font-medium", {
                              "bg-white bg-opacity-10 text-stone-900": isCurrentPage,
                              "text-stone-900 hover:bg-white hover:bg-opacity-10": !isCurrentPage,
                            })}
                            aria-current={isCurrentPage}
                          >
                            {item.label}
                          </Disclosure.Button>
                        )}
                        {item.page?.[0] && (
                          <Link
                            key={item._id}
                            href={`/${slug !== "index" ? slug : ""}`}
                            title={item.label}
                            className={classNames("block w-full rounded-md py-2 px-3 text-base font-medium", {
                              "bg-white bg-opacity-10 text-white": isCurrentPage,
                              "text-white hover:bg-white hover:bg-opacity-10": !isCurrentPage,
                            })}
                          >
                            <Disclosure.Button as="span">{item.label}</Disclosure.Button>
                          </Link>
                        )}
                      </>
                    );
                  })}
                </div>
              </Disclosure.Panel>
            </div>
            <div className="absolute top-0 right-0 left-0 flex items-center justify-center lg:relative">
              <Link href="/">
                <Logo className="h-12 w-32 lg:h-16 lg:w-40" />
              </Link>
            </div>
            <div className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-8">
              <div className="font-display font-extrabold text-white">Luister op</div>
              <div className="inline-flex justify-center">
                <a
                  href={globalSettings?.tunein_url}
                  target="_blank"
                  title="Luister ZTACK op TuneIn"
                  rel="noreferrer"
                  className="text-white transition-colors duration-150 hover:text-stone-100"
                >
                  <SvgIconTunein className="h-8 w-8" />
                </a>
              </div>
            </div>
          </div>
          <div className="z-10 mt-4 flex w-full overflow-hidden lg:mx-auto lg:mt-16 lg:grid lg:max-w-7xl lg:grid-cols-5 lg:flex-row lg:justify-start lg:space-x-16 lg:pb-16">
            <div className="hidden lg:col-span-2 lg:flex lg:flex-col lg:justify-center">
              <h2 className="font-display text-3xl font-extrabold text-white">Nu & straks</h2>
              <div
                className="mt-4 flex h-32 overflow-hidden rounded-2xl bg-white shadow-md"
                title={playerStore.nowPlaying.full}
              >
                <div className="relative h-32 w-32">
                  <Image
                    src={playerStore.nowPlaying.albumArt}
                    alt={playerStore.nowPlaying.full}
                    fill
                    className="object-cover"
                  />
                </div>
                <div className="mx-4 flex flex-1 flex-col items-start justify-center overflow-hidden">
                  <div className="max-w-full truncate font-display text-3xl font-extrabold">
                    {playerStore.nowPlaying.title}
                  </div>
                  <div className="max-w-full truncate font-display text-lg">
                    {playerStore.nowPlaying.artist}
                  </div>
                </div>
                <div className="flex items-center justify-center px-4">
                  <button
                    className="inline-flex items-center justify-center rounded-full bg-primary p-3 text-black"
                    onClick={() => {
                      if (playerStore.isPlaying) {
                        playerStore.pause();

                        if (playerStore.file === radioStream) {
                          return;
                        }
                      }

                      if (playerStore.file !== radioStream) {
                        playerStore.resetFile();
                      }

                      playerStore.play();
                    }}
                  >
                    {(playerStore.isPaused || !playerStore.isPlaying || playerStore.file !== radioStream) && (
                      <SvgPlay className="h-6 w-6 text-white" />
                    )}
                    {playerStore.isPlaying && playerStore.file === radioStream && (
                      <SvgPause className="h-6 w-6 text-white" />
                    )}
                  </button>
                </div>
              </div>

              <div className="mt-4 grid grid-cols-2 gap-4">
                <div>
                  <h2 className="font-display text-xl font-extrabold text-white">Vorige track</h2>
                  <div className="mt-1 flex h-20 overflow-hidden rounded-2xl bg-white shadow-md">
                    <div className="relative h-20 w-20">
                      <Image
                        src={playerStore.previousTrack.albumArt}
                        alt={playerStore.previousTrack.full}
                        fill
                        className="object-cover"
                      />
                    </div>
                    <div
                      className="mx-4 flex flex-1 flex-col items-start justify-center overflow-hidden"
                      title={playerStore.previousTrack.full}
                    >
                      <div className="max-w-full truncate font-display text-xl font-extrabold">
                        {playerStore.previousTrack.title}
                      </div>
                      <div className="max-w-full truncate font-display text-sm">
                        {playerStore.previousTrack.artist}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="font-display text-xl font-extrabold text-white">Zometeen</h2>
                  <div
                    className="mt-1 flex h-20 overflow-hidden rounded-2xl bg-white shadow-md"
                    title={playerStore.nextTrack.full}
                  >
                    <div className="relative h-20 w-20">
                      <Image
                        src={playerStore.nextTrack.albumArt}
                        alt={playerStore.nextTrack.full}
                        fill
                        className="object-cover"
                      />
                    </div>
                    <div className="mx-4 flex flex-1 flex-col items-start justify-center overflow-hidden">
                      <div className="max-w-full truncate font-display text-xl font-extrabold">
                        {playerStore.nextTrack.title}
                      </div>
                      <div className="max-w-full truncate font-display text-sm">
                        {playerStore.nextTrack.artist}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!open && (
              <div className="relative w-full overflow-hidden rounded-2xl bg-black shadow-md lg:col-span-3 lg:w-auto lg:max-w-4xl">
                <Swiper
                  modules={[Navigation, Autoplay, Lazy, EffectFade]}
                  slidesPerView={1}
                  onSwiper={swiper => setSwiper(swiper)}
                  autoplay={{
                    delay: 5000,
                    pauseOnMouseEnter: true,
                  }}
                  loop
                  effect="fade"
                  lazy={{
                    enabled: true,
                    loadOnTransitionStart: true,
                    loadPrevNext: true,
                  }}
                >
                  {page.banners?.map(banner => (
                    <SwiperSlide key={banner._id}>
                      <div className="relative w-full  overflow-hidden rounded-2xl">
                        <div className="aspect-h-9 aspect-w-16">
                          <a href={banner?.link ?? "#"}>
                            <Image
                              alt={banner!.image![0]!.caption ?? ""}
                              fill
                              className="object-cover"
                              src={banner!.image![0].url!}
                            />
                          </a>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
          </div>
        </>
      )}
    </Disclosure>
  );
}
