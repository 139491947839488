export { default as SvgIconApple } from "./IconApple";
export { default as SvgIconFacebook } from "./IconFacebook";
export { default as SvgIconGooglePodcast } from "./IconGooglePodcast";
export { default as SvgIconInstagram } from "./IconInstagram";
export { default as SvgIconSpotify } from "./IconSpotify";
export { default as SvgIconTunein } from "./IconTunein";
export { default as SvgIconTwitter } from "./IconTwitter";
export { default as SvgIconYoutube } from "./IconYoutube";
export { default as SvgLogo } from "./Logo";
export { default as SvgPause } from "./Pause";
export { default as SvgPlay } from "./Play";
